
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import GridLayout from "../../components/GridLayout"
import PageLayout from "../../components/PageLayout"
import GridTextProjectElement from "../../components/GridTextProjectElement"
import { projectNames } from "../../context/consts"
import Seo from "../../components/Seo"


const cookplayTearsPage = () => {

    const imageComponents = [
        <StaticImage key={1} src="./../../images/cookplay-tears/cookplay-tears-0.jpg" alt="" layout="fullWidth"></StaticImage>,
        <GridTextProjectElement key={2} projectName={projectNames.COOKPLAY_TEARS} />,
        <StaticImage key={3} src="./../../images/cookplay-tears/cookplay-tears-1.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={4} src="./../../images/cookplay-tears/cookplay-tears-2.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={5} src="./../../images/cookplay-tears/cookplay-tears-3.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={6} src="./../../images/cookplay-tears/cookplay-tears-4.jpg" alt="" layout="fullWidth"></StaticImage>,
    ]

    return (
        <PageLayout firstHead="cookplay" secondHead="tears collection">
            <GridLayout components={imageComponents}></GridLayout>
            <Seo subTitle="cookplay tears collection" />
        </PageLayout>
    )

}

export default cookplayTearsPage